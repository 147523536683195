<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer" size="lg">
    <h6 slot="header" class="modal-title">메시지 상세</h6>
    <template>
      <div class="form-group row mb-0">
        <label class="col-md-3 col-form-label form-control-label">메시지 ID</label>
        <div class="col-md-8">
          <base-input v-model="seqNo" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">고객 KEY</label>
        <div class="col-md-8">
          <base-input v-model="info.cid" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">템플릿코드</label>
        <div class="col-md-8">
          <base-input v-model="info.templateCode" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">발송일</label>
        <div class="col-md-8">
          <base-input v-model="info.regDate" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">결과수신일</label>
        <div class="col-md-8">
          <base-input v-model="info.endDate" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">MSG_TYPE</label>
        <div class="col-md-8">
          <base-input v-model="info.msgType" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">STATUS_CD</label>
        <div class="col-md-8">
          <base-input v-model="info.statusCode" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">KKO_CD</label>
        <div class="col-md-8">
          <base-input v-model="info.kkoCd" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">SMS_CD</label>
        <div class="col-md-8">
          <base-input v-model="info.smsCd" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">수신번호</label>
        <div class="col-md-8">
          <base-input v-model="info.phoneNumber" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label">발신번호</label>
        <div class="col-md-8">
          <base-input v-model="info.senderNo" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label" v-if="info.errorCode && info.errorCode != 'OK'">에러코드</label>
        <div class="col-md-8" v-if="info.errorCode && info.errorCode != 'OK'">
          <base-input v-model="info.errorCode" disabled>
          </base-input>
        </div>
        <label class="col-md-3 col-form-label form-control-label" v-if="info.errorCode && info.errorCode != 'OK'">에러메시지</label>
        <div class="col-md-8" v-if="info.errorCode && info.errorCode != 'OK'">
          <base-input v-model="info.errorMsg" disabled>
          </base-input>
        </div>
      </div>
      <div class="form-group row mb-10">
        <label class="col-md-3 col-form-label form-control-label">내용</label>
        <div class="col-md-8">
          <textarea class="form-control" rows="5" disabled v-model="info.kkoMsg"></textarea>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-md-3 col-form-label form-control-label">문자 제목</label>
        <div class="col-md-8">
          <base-input v-model="info.title" disabled>
          </base-input>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-md-3 col-form-label form-control-label">문자 내용</label>
        <div class="col-md-8">
          <textarea class="form-control" rows="5" disabled v-model="info.smsMsg"></textarea>
        </div>
      </div>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
    </template>

  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX} from '@/shared/util/const';

  export default {
    name: 'RealTimeResultLayer',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    props: {
      seqNo       : String  ,
      messageType : String  ,
      clientId    : String  ,
      offerCode   : String
    },
    computed: {

    },
    data() {
      return {
        info: {
          regDate       : '' ,
          endDate       : '' ,
          msgType       : '' ,
          statusCode    : '' ,
          kkoCd         : '' ,
          smsCd         : '' ,
          templateCode  : '' ,
          phoneNumber   : '' ,
          senderNo      : '' ,
          errorCode     : '' ,
          errorMsg      : '' ,
          kkoMsg        : '' ,
          smsMsg        : '' ,
          cid           : '' ,
          title         : '' ,
        }
      }
    },
    created() {

      this.getInfo()
    },
    methods: {
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close');
      },

      /**
       * 메시지정보 정보 조회
       */
      getInfo() {
        this.$axios.get(`${USER_API_PREFIX}/real-time/detail`, {
          params: this.setParams()
        }).then(res => {
          let resInfo = res.data.result.info;
          this.info.regDate       = resInfo.regDate       ;
          this.info.endDate       = resInfo.endDate       ;
          this.info.msgType       = resInfo.msgType       ;
          this.info.statusCode    = resInfo.statusCode    ;
          this.info.kkoCd         = resInfo.kkoCd         ;
          this.info.smsCd         = resInfo.smsCd         ;
          this.info.templateCode  = resInfo.templateCode  ;
          this.info.phoneNumber   = resInfo.phoneNumber   ;
          this.info.senderNo      = resInfo.senderNo      ;
          this.info.kkoMsg        = resInfo.kkoMsg        ;
          this.info.smsMsg        = resInfo.smsMsg        ;
          this.info.errorCode     = resInfo.errorCode     ;
          this.info.errorMsg      = resInfo.errorMsg      ;
          this.info.cid           = resInfo.cid           ;
          this.info.title         = resInfo.title         ;
        });
      },

      setParams() {
        return {
          seqNo       : this.seqNo        ,
          clientId    : this.clientId     ,
          messageType : this.messageType  ,
          offerCode   : this.offerCode
        }
      }
    }
  };
</script>
<style>
</style>
