<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">실시간 발송 조회</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">실시간 발송 목록</h3>
          </template>
          <!--          상세보기 레이어-->
          <div>
            <div class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="form-inline " style="margin-bottom: 1rem;">
                <div class="mb-0 col-md-6 form-group pl-0 pr-0">
                  <label class="col-md-3 col-form-label form-control-label">메시지유형<span class="text-danger">*</span></label>
                  <div class="col-md-9 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.messageType"
                                 filterable
                                 placeholder="메시지유형 선택">
                        <el-option v-for="option in MessageTypeOptions"
                                   :key="option.value"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="mb-0 col-md-5 form-group pl-0 pr-0"  v-if="session.clientType == 'R'">
                  <label class="col-md-3 col-form-label form-control-label">고객사</label>
                  <div class="col-md-9 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.clientId"
                                 filterable
                                 placeholder="고객사 선택">
                        <el-option v-for="option in clientNameList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="mb-0 col-md-5 form-group pl-0 pr-0"  v-else ></div>
                <div class="mb-0 col-md-6 form-group pl-0 pr-0" style="margin-top: 1rem;">
                  <label class="col-md-3 col-form-label form-control-label">발송일시<span class="text-danger">*</span></label>
                    <div class="col-md-9 pl-0 pr-0 form-inline">
                      <div class="col-3 pl-0">
                        <el-select v-model="search.startYearValue"
                                   filterable
                                   placeholder="연도 선택"
                                   @change="resetDate('START','YEAR')">
                          <el-option v-for="option in yearList"
                                     :key="option.label"
                                     :label="option.label"
                                     :value="option.value">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-3 pl-0">
                        <el-select v-model="search.startMonthValue"
                                   filterable
                                   placeholder="월 선택"
                                   @change="resetDate('START','MONTH')">
                          <el-option v-for="option in monthList"
                                     :key="option.label"
                                     :label="option.label"
                                     :value="option.value">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-3 pl-0">
                        <el-select v-model="search.startDayValue"
                                   filterable
                                   placeholder="일 선택"
                                   @change="resetDate('START','DAY')">
                          <el-option v-for="option in dayList"
                                     :key="option.label"
                                     :label="option.label"
                                     :value="option.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                </div>
                <div class="mb-0 col-md-5 form-group pl-0 pr-0" style="margin-top: 1rem;">
                  <label class="col-md-3 col-form-label form-control-label">전화번호<span class="text-danger">*</span></label>
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="전화번호 입력" aria-label="전화번호 입력" @keyup="onlyNumber" :maxlength="15" aria-describedby="button-addon2"
                           v-model="search.phoneNumber">
                  </base-input>
                </div>

                <div class="mb-0 col-md-6 form-group pl-0 pr-0" >
                  <label class="col-md-3 col-form-label form-control-label">고객KEY</label>
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="KEY 입력" aria-label="KEY 입력" :maxlength="30" aria-describedby="button-addon2"
                           v-model="search.cid"
                           @keydown.enter="retrieveRealTimeResultList(1)">
                  </base-input>
                </div>
                <div class="mb-0 col-md-5 form-group pl-0 pr-0">
                  <label class="col-md-3 col-form-label form-control-label">성공여부</label>
                  <div class="col-md-9 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.successYn"
                                 filterable
                                 placeholder="성공여부">
                        <el-option v-for="option in selectSuccessOptions"
                                   :key="option.value"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="form-inline" style="margin-top: 1rem;">
                  <base-button size="lg" outline type="primary" @click="retrieveRealTimeResultList(1)">검색</base-button>
                </div>
              </div>
            </div>

            <el-table :data="realTimeResultList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'조회된 정보가 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column label="번호" min-width="6%">
                <template slot-scope="scope">
                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}
                </template>
              </el-table-column>
              <el-table-column prop="regDate" label="발송일시" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.regDate }}
                </template>
              </el-table-column>
              <el-table-column prop="endDate" label="결과수신일" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.endDate }}
                </template>
              </el-table-column>
              <el-table-column prop="phoneNumber" label="수신번호" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.phoneNumber }}
                </template>
              </el-table-column>
              <el-table-column prop="senderNo" label="발신번호" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.senderNo }}
                </template>
              </el-table-column>
              <el-table-column prop="msgType" label="타입" min-width="7%">
                <template slot-scope="scope">
                  {{ scope.row.msgType }}
                </template>
              </el-table-column>
              <el-table-column prop="offerCode" label="발송유형" min-width="9%">
                <template slot-scope="scope">
                  {{ scope.row.offerCode }}
                </template>
              </el-table-column>
              <el-table-column prop="statusCode" label="상태코드" min-width="10%">
                <template slot-scope="scope">
                  <el-popover placement="bottom">
                    <span>{{ scope.row.codeDesc }}</span>
                    <base-button slot="reference" size="sm" type="default">{{ scope.row.statusCode | stautsFilter}}</base-button>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="kkoCd" label="카카오코드" min-width="10%">
                <template slot-scope="scope">
                  <el-popover placement="bottom" v-if="scope.row.kkoCd != null && scope.row.kkoCd != ''">
                    <span>{{ scope.row.kkoCodeDesc }}</span>
                    <base-button slot="reference" size="sm" type="default">{{ scope.row.kkoCd | stautsFilter}}</base-button>
                  </el-popover>
                  <div v-else>
                    {{ scope.row.kkoCd }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="smsCd" label="문자코드" min-width="10%">
                <template slot-scope="scope">
                  <el-popover placement="bottom" v-if="scope.row.smsCd != null && scope.row.smsCd != ''">
                    <span>{{ scope.row.smsCodeDesc }}</span>
                    <base-button slot="reference" size="sm" type="default">{{ scope.row.smsCd | stautsFilter}}</base-button>
                  </el-popover>
                  <div v-else>
                    {{ scope.row.smsCd }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="cid" label="고객KEY" min-width="10%">
                <template slot-scope="scope">
                  {{ scope.row.cid }}
                </template>
              </el-table-column>
              <el-table-column prop="kkoMsg" label="메시지내용" min-width="10%">
                <template slot-scope="scope">
                  {{ scope.row.kkoMsg }}
                </template>
              </el-table-column>
              <el-table-column prop="seqNo" label="상세보기" min-width="10%">
                <template slot-scope="scope">
                  <base-button type="info" size="sm" @click="openDetailLayer(scope.row)">
                    상세보기
                  </base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="retrieveRealTimeResultList">
            </base-pagination>

            <real-time-result-layer
              v-if="showDetailLayer"
              :seq-no="seqNo"
              :message-type="search.messageType"
              :client-id="clientId"
              :offer-code="offerCode"
              @close="closeLayer">
            </real-time-result-layer>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX } from '@/shared/util/const'
  import RealTimeResultLayer from './RealTimeResultLayer';
  import swal from "sweetalert2";
  import {mapGetters} from "vuex";

  export default {
    name: 'RealTimeResult',
    mixins: [ commFlatpickrMixin, commJsUtilMixin, commPaginationMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      RealTimeResultLayer
    },
    computed : {
      ...mapGetters({
        session: 'SessionStore/getSession'
      })
    },
    filters: {
      stautsFilter: value => {
        let name = value;
        switch (value) {
          case 'API_200':
            name = '성공';
            break;
          case 'API_201':
            name = '발송중';
            break;
          case 'API_202':
            name = '발송중';
            break;
          case 'API_203':
            name = '발송중';
            break;
          case 'OK':
            name = '성공';
            break;
        }
        return name;
      },
    },
    created() {
      // 연도 목록 세팅
      let fullDays                = this._fullDays('Y', 0);
      this.yearList               = fullDays.yearList;
      this.search.startYearValue  = fullDays.year;

      // 월 목록 세팅
      this.monthList              = fullDays.monthList;
      this.search.startMonthValue = fullDays.month;

      // 일 목록 세팅
      this.dayList                = fullDays.dayList;
      this.search.startDayValue   = fullDays.day;

      if(this.session.clientType == 'R'){
        this.retrieveSubClientNameList();
      }

      this.setParams(1)

    },
    data() {
      return {
        showDetailLayer   : false,
        seqNo             : undefined,
        clientId          : '',
        offerCode         : '',
        realTimeResultList: [],
        yearList          : [],
        monthList         : [],
        dayList           : [],
        clientNameList    : [],
        selectDateOptions : [
          {
            label: '연별',
            value: 'YEAR'
          },
          {
            label: '월별',
            value: 'MONTH'
          },
          {
            label: '일별',
            value: 'DAY'
          }
        ],
        MessageTypeOptions: [
          {
            label: '알림톡',
            value: 'AT'
          },
          {
            label: '친구톡',
            value: 'FT'
          },
          {
            label: 'SMS',
            value: 'SM'
          },
          {
            label: 'MMS/LMS',
            value: 'MM'
          }
        ],
        selectSuccessOptions : [
          {
            label: '선택',
            value: ''
          },
          {
            label: '성공',
            value: 'Y'
          },
          {
            label: '실패',
            value: 'N'
          }
        ],
        search: {
          dateTypeCondition : 'DAY' ,
          startYearValue    : null  ,
          startMonthValue   : null  ,
          startDayValue     : this.$moment(new Date()).format('YYYY-MM-DD') ,
          messageType       : null  ,
          cid               : null  ,
          successYn         : ''    ,
          clientId          : ''    ,
          phoneNumber       : ''    ,
        }
      }
    },
    methods: {
      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },

      /**
       * 정산정보 목록 조회
       */
      retrieveRealTimeResultList(page) {
        let chk = true;

        if(this.checkNull(this.search.messageType)){
          this.alertMsg('메시지유형을 선택해 주세요.');
          chk = false;
          return false;
        }

        if(this.checkNull(this.search.startMonthValue) || this.checkNull(this.search.startDayValue)) {
          this.alertMsg('조회일 선택해 주세요.');
          chk = false;
          return false;
        }

        if(this.checkNull(this.search.phoneNumber)){
          this.alertMsg('전화번호를 입력해 주세요.');
          chk = false;
          return false;
        }
        if(chk){
          let pageNo = page ? page : this.pagination.currentPage;
          this.$axios.get(`${USER_API_PREFIX}/real-time/result`, {
            params: this.setParams(pageNo)
          }).then(res => {
            this.realTimeResultList     = res.data.result.realTimeResultList; // 목록정보 세팅
            this.pagination.currentPage = pageNo; // 페이지 번호 세팅
            this.pagination.total       = res.data.result.total; // 전체페이지수 세팅
          });
        }
      },

      /**
       * 연도/월/일자에 선택에 따른 날짜 초기화
       */
      resetDate(type, period) {
        switch (period) {
          case 'YEAR' :
            this.search.startMonthValue = ''
            this.search.startDayValue   = ''
            break
          case 'MONTH' :
              this.search.startDayValue   = ''
            break
        }
      },

      /**
       * 상세 레이어 생성
       */
      openDetailLayer(row) {
        this.showDetailLayer = true;
        this.seqNo = row.seqNo;
        this.clientId = row.clientId;
        this.offerCode = row.offerCode;
      },

      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.showDetailLayer = false;
      },

      /**
       * 키 이벤트로 오직 숫자만 입력되도록 처리
       */
      onlyNumber() {
        this.search.phoneNumber = this._onlyNumber(this.search.phoneNumber);
      },

      /**
       * null check
       */
      checkNull(param){
        if(param === undefined || param === null || '' == param){
          return true;
        }else{
          return false;
        }
      },

      /**
       *
       */
      retrieveSubClientNameList() {
        this.$axios.get(`${USER_API_PREFIX}/resell/sub-clients`, {
        }).then((res) => {
          this.clientNameList = res.data.result.clientNameList;
          this.clientNameList.unshift({
            value: '',
            label: '== 전체 =='
          })
        }).catch((error) => {
          console.log(error)
        })
      },

      /**
       * 일별/월별에 따른 파라미터 세팅
       */
      setParams(pageNo) {
        return {
          page          : pageNo ? pageNo : 1               ,
          rows          : Number(this.pagination.perPage)   ,
          clientId      : this.search.clientId              ,
          startYear     : this.search.startYearValue        ,
          startMonth    : this.search.startMonthValue       ,
          startDay      : this.search.startDayValue         ,
          endYear       : this.search.startYearValue        ,
          endMonth      : this.search.startMonthValue       ,
          endDay        : this.search.startDayValue         ,
          phoneNumber   : this.search.phoneNumber           ,
          successYn     : this.search.successYn             ,
          messageType   : this.search.messageType           ,
          cid           : this.search.cid
        }
      }
    }
  }
</script>

